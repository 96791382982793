import React, { PureComponent } from 'react'

import { helpers, withBasic } from '@common'
import Icon from '@pc/components/Icon'

import styles from './style.m.scss'

import icon_high_quota from '@pc/assets/img/ic_High_amount.@3x.png'
import icon_low_interest from '@pc/assets/img/ic_Low_interest_rates@3x.png'
import icon_more_promotion from '@pc/assets/img/ic_More_offers@3x.png'

const className = helpers.classNames.react(styles)

class DiscountItem extends PureComponent {
  render() {
    const { text, desc, icon } = this.props

    return (
      <div {...className('discount-item-area font-lato-medium')}>
        <div {...className('discount-item-icon')}>
          <i>{icon}</i>
        </div>
        <div {...className('discount-item-body')}>
          <p {...className('discount-item-text')}>{text}</p>
          <p {...className('discount-item-desc')}>{desc}</p>
        </div>
      </div>
    )
  }
}

@withBasic
export default class MegaDiscountList extends PureComponent {
  render() {
    const { translate, type } = this.props
    return (
      <div {...className(`mega-discount-list${type === 'pc' ? '-pc' : ''}`)}>
        <DiscountItem
          text={translate('高额度')}
          desc={translate('多种分期选择，最高可分12期')}
          icon={
            type === 'pc' ? (
              <Icon icontype="svg" name="hightLimit" />
            ) : (
              <Icon icontype="image" src={icon_low_interest} />
            )
          }
        />
        <DiscountItem
          text={translate('低利息')}
          desc={translate('优质用户还有0利息特权哦')}
          icon={
            type === 'pc' ? (
              <Icon icontype="svg" name="zeroDP" />
            ) : (
              <Icon icontype="image" src={icon_high_quota} />
            )
          }
        />
        <DiscountItem
          text={translate('优惠活动')}
          desc={translate('各类优惠活动持续不断')}
          icon={
            type === 'pc' ? (
              <Icon icontype="svg" name="gift" />
            ) : (
              <Icon icontype="image" src={icon_more_promotion} />
            )
          }
        />
      </div>
    )
  }
}
