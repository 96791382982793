import React, { PureComponent } from 'react'
import { PhoneInput, PasswordInput } from '../InputFields'
import { connect } from 'react-redux'

import BizTracker from '@pc/common/tracker/bizTracker'
import { globalActionCreator } from '@pc/common/globalActions'
import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'
import Logger from '@pc/config/logger'
import { Button } from '@pc/components/Button'

import loggerConfig from '../../logger.config'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class LoginForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      phoneNumber: '',
      password: '',
      phoneStatus: false, //手机号码是否合法
      imageCaptcha: '', // 图形验证码
    }
    this.controls = []
    this.saLogger = Logger.getInstance()
  }

  // 切换tab的适合用来同步用户填写的手机号
  componentWillUnmount() {
    // const { phoneNumber } = this.state
    // const { setDefaultPhoneNumber } = this.props
    // phoneNumber && setDefaultPhoneNumber(phoneNumber)
  }

  handlePhoneInput = (status) => {
    this.setState({
      phoneStatus: status,
    })
  }

  handleCodeInput = (status) => {
    this.setState({
      codeStatus: status,
    })
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  submitPassword = () => {
    BizTracker.click({
      cn: 13,
      sn: 390001,
    })
    const {
      loginWithPwd,
      setPasswordErrorText,
      setPhoneNumberErrorText,
      curPeriod,
      callbackPageUrl,
      defaultPhoneNumber,
      needPwdLoginGraphicCode,
    } = this.props
    const { password, phoneNumber, imageCaptcha } = this.state

    const result = this.controls.every((elem) => {
      // if (elem.name !== 'password') return true //只校验密码
      const errMsg = elem.validate(
        elem.state.value === null ? elem.props.defaultValue : elem.state.value,
      )

      if (errMsg === false) {
        return true
      } else {
        if (elem.name === 'password') {
          setPasswordErrorText(errMsg)
        } else {
          setPhoneNumberErrorText(errMsg)
        }
        return false
      }
    })

    if (result) {
      const params = {
        phoneNumber: phoneNumber ? phoneNumber : defaultPhoneNumber,
        password,
        curPeriod,
        callbackPageUrl,
      }
      needPwdLoginGraphicCode && (params.imageCaptcha = imageCaptcha)
      loginWithPwd(params)
      this.saLogger.onPageClick({
        ...loggerConfig.login,
        Aku_channelName: 'account',
      })
    }
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  render() {
    const {
      translate,
      countryCode,
      setPasswordErrorText,
      setPhoneNumberErrorText,
      pwdErrorText,
      phoneNumberErrorText,
      setValidPhoneNumber,
      type,
      defaultPhoneNumber,
      onForget,
      canModifyPhoneNumber,
    } = this.props

    return [
      <form
        key="loginForm"
        {...className('form-login', {
          'form-login-pc': type === 'pc',
        })}
      >
        {type !== 'pc' ? null : (
          <p {...className('form-title font-lato-reg')}>
            {translate('手机号')}
          </p>
        )}
        <PhoneInput
          name="phoneNumber"
          type="text"
          placeholder={translate('手机号')}
          onRegister={this.register}
          onControl={this.collect}
          defaultValue={defaultPhoneNumber}
          validation={{ type: 'phone' }}
          onChange={(value) => {
            // 手机号码输入框
            BizTracker.input({
              key: 'password-login-phoneinput',
              cn: 9,
              ic: value,
              sn: 390001,
            })
          }}
          onSuccess={() => {
            setPhoneNumberErrorText('')
            setTimeout(() => {
              //取最新的phoneNumber 给忘记密码用的
              setValidPhoneNumber(this.state.phoneNumber)
            }, 0)
            this.handlePhoneInput(true)
          }}
          onError={(errMsg) => {
            setValidPhoneNumber('')
            setPhoneNumberErrorText(errMsg)
            this.handlePhoneInput(false)
          }}
          onFocus={() => {
            setPhoneNumberErrorText('')
            this.saLogger.onPageClick({
              ...loggerConfig.click_phone_num,
              Aku_channelName: 'account',
            })
          }}
          onBlur={() => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_phone_num,
              Aku_channelName: 'account',
            })
          }}
          errorText={phoneNumberErrorText}
          countryCode={countryCode}
          required
          platform={type === 'pc' ? 'pc' : 'mobile'}
          hasClearIcon
          disabled={
            canModifyPhoneNumber !== undefined && canModifyPhoneNumber !== null
              ? !canModifyPhoneNumber
              : false
          }
        />
        {type !== 'pc' ? null : (
          <p {...className('form-title font-lato-reg')}>{translate('密码')}</p>
        )}
        <PasswordInput
          type="password"
          name="password"
          placeholder={translate('请输入密码')}
          onRegister={this.register}
          onControl={this.collect}
          onFocus={() => {
            setPasswordErrorText('')
            this.saLogger.onPageClick({
              ...loggerConfig.click_password_box,
              Aku_channelName: 'account',
            })
          }}
          onChange={(value) => {
            // 手机密码输入框
            BizTracker.input({
              key: 'password-login-passwordinput',
              cn: 10,
              ic: value,
              sn: 390001,
            })
          }}
          onBlur={() => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_password,
              Aku_channelName: 'account',
            })
          }}
          onSuccess={() => {
            setPasswordErrorText('')
          }}
          errorText={pwdErrorText}
          required
          platform={type === 'pc' ? 'pc' : 'mobile'}
          {...className('login-password-input')}
          hasClearIcon
        />
        {/* 绑定账号 toko需求因第三方原因暂时无法进行，故关闭绑定账号功能 */}
        {/* <ConnectAccount
          channelType={'account'}
          isShow={showBindProtocol}
          translate={translate}
          checked={agreeBindProtocol}
          onChange={handleChangeAgreeBindProtocol}
        /> */}
        <Button
          text={translate('登录')}
          disabled={false /* !codeStatus || !phoneStatus */}
          onClick={this.submitPassword}
          platform={type === 'pc' ? 'pc' : 'mobile'}
          {...className('', {
            'btn-login-pc': type === 'pc',
            'btn-login': type !== 'pc',
          })}
        />
        {type !== 'pc' && (
          <p
            {...className('btn-forgot-pwd')}
            onClick={() => {
              BizTracker.click({
                sn: 390001,
                cn: 11,
              })
              // 修改密码弹窗曝光
              BizTracker.expose({
                cn: 1,
                sn: 390002,
              })
              // this.saLogger.onPageClick(elementId.CLICK_FORGOT_PASSWORD)
              this.saLogger.onPageClick(loggerConfig.forget_password_popup)
              this.saLogger.onPageClick({
                ...loggerConfig.forget_password,
                Aku_channelName: 'account',
              })
              onForget && onForget()
            }}
          >
            {translate('忘记密码？')}
          </p>
        )}
      </form>,
    ]
  }
}

const mapStateToProps = (state) => ({
  sent: state.getIn(['login', 'sent']),
  countDown: state.getIn(['login', 'countDown']),
  pwdErrorText: state.getIn(['login', 'pwdErrorText']),
  phoneNumberErrorText: state.getIn(['login', 'phoneNumberErrorText']),
  callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
  defaultPhoneNumber: state.getIn(['login', 'defaultPhoneNumber']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  needPwdLoginGraphicCode: state.getIn(['login', 'needPwdLoginGraphicCode']),
  graphicUrl: state.getIn(['register', 'graphicUrl']),
  graphicErrorText: state.getIn(['register', 'graphicErrorText']),
  showBindProtocol: state.getIn(['main', 'showBindProtocol']),
  canModifyPhoneNumber: state.getIn(['main', 'canModifyPhoneNumber']),
  agreeBindProtocol: state.getIn(['login', 'agreeBindProtocol']),
})

const mapDispatchToProps = (dispatch) => ({
  setValidPhoneNumber(validPhoneNumber) {
    dispatch(actionCreator.goSetCurValidPhoneNumber(validPhoneNumber))
  },
  setPasswordErrorText(text) {
    dispatch(actionCreator.goSetPasswordErrorText(text))
  },
  setPhoneNumberErrorText(text) {
    dispatch(actionCreator.goSetPhoneNumberErrorText(text))
  },
  loginWithPwd(params) {
    dispatch(actionCreator.goLoginWithPwd(params))
  },
  showGlobalToast(errMsg) {
    dispatch(globalActionCreator.toggleToast(true, errMsg))
  },
  setDefaultPhoneNumber(phoneNumber) {
    dispatch(actionCreator.goSetDefaultPhoneNumber(phoneNumber))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
